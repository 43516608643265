define("ember-central-navbar/models/requirement", ["exports", "@clerky/ember-shared/models/requirement"], function (_exports, _requirement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _requirement.default;
    }
  });
});