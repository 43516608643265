define("ember-central-navbar/models/document-set-access-invitation-mailing", ["exports", "@clerky/ember-shared/models/document-set-access-invitation-mailing"], function (_exports, _documentSetAccessInvitationMailing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentSetAccessInvitationMailing.default;
    }
  });
});