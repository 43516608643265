define("ember-central-navbar/models/form-containing-range", ["exports", "@clerky/ember-shared/models/form-containing-range"], function (_exports, _formContainingRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formContainingRange.default;
    }
  });
});