define("ember-central-navbar/components/document-set-initializer/document-set-template-selection-modal/template-panel", ["exports", "@clerky/ember-shared/components/document-set-initializer/document-set-template-selection-modal/template-panel"], function (_exports, _templatePanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _templatePanel.default;
    }
  });
});