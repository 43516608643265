define("ember-central-navbar/components/document-set-initializer/document-set-template-version-selection-modal", ["exports", "@clerky/ember-shared/components/document-set-initializer/document-set-template-version-selection-modal"], function (_exports, _documentSetTemplateVersionSelectionModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentSetTemplateVersionSelectionModal.default;
    }
  });
});