define("ember-central-navbar/components/team-dashboard/nav-items", ["exports", "@clerky/ember-shared/components/team-dashboard/nav-items"], function (_exports, _navItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _navItems.default;
    }
  });
});