define("ember-central-navbar/macros/belongs-to-reference-is-set", ["exports", "@clerky/ember-shared/macros/belongs-to-reference-is-set"], function (_exports, _belongsToReferenceIsSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _belongsToReferenceIsSet.default;
    }
  });
});