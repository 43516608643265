define("ember-central-navbar/instance-initializers/error-handling", ["exports", "@clerky/ember-shared/instance-initializers/error-handling"], function (_exports, _errorHandling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _errorHandling.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _errorHandling.initialize;
    }
  });
});