define("ember-central-navbar/helpers/url-with-protocol", ["exports", "@clerky/ember-shared/helpers/url-with-protocol"], function (_exports, _urlWithProtocol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _urlWithProtocol.default;
    }
  });
  Object.defineProperty(_exports, "urlWithProtocol", {
    enumerable: true,
    get: function () {
      return _urlWithProtocol.urlWithProtocol;
    }
  });
});