define("ember-central-navbar/services/current-route-information", ["exports", "@clerky/ember-shared/services/current-route-information"], function (_exports, _currentRouteInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _currentRouteInformation.default;
    }
  });
});