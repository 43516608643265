define("ember-central-navbar/components/groups/membership-trigger-modal/template-finalized-step", ["exports", "@clerky/ember-shared/components/groups/membership-trigger-modal/template-finalized-step"], function (_exports, _templateFinalizedStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _templateFinalizedStep.default;
    }
  });
});