define("ember-central-navbar/mixins/current-route", ["exports", "@clerky/ember-shared/mixins/current-route"], function (_exports, _currentRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _currentRoute.default;
    }
  });
});