define("ember-central-navbar/helpers/readable-url", ["exports", "@clerky/ember-shared/helpers/readable-url"], function (_exports, _readableUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _readableUrl.default;
    }
  });
  Object.defineProperty(_exports, "readableUrl", {
    enumerable: true,
    get: function () {
      return _readableUrl.readableUrl;
    }
  });
});