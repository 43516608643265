define("ember-central-navbar/components/billing-setup-modal/select-plan-step", ["exports", "@clerky/ember-shared/components/billing-setup-modal/select-plan-step"], function (_exports, _selectPlanStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _selectPlanStep.default;
    }
  });
});