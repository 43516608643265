define("ember-central-navbar/components/billing-setup-modal/legal-care-package-deal-detail", ["exports", "@clerky/ember-shared/components/billing-setup-modal/legal-care-package-deal-detail"], function (_exports, _legalCarePackageDealDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _legalCarePackageDealDetail.default;
    }
  });
});