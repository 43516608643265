define("ember-central-navbar/initializers/wysiwyg-token-highlighter", ["exports", "@clerky/ember-shared/initializers/wysiwyg-token-highlighter"], function (_exports, _wysiwygTokenHighlighter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _wysiwygTokenHighlighter.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _wysiwygTokenHighlighter.initialize;
    }
  });
});