define("ember-central-navbar/mixins/model-type-extensions/document-set-template-invitation-request", ["exports", "@clerky/ember-shared/mixins/model-type-extensions/document-set-template-invitation-request"], function (_exports, _documentSetTemplateInvitationRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentSetTemplateInvitationRequest.default;
    }
  });
});