define("ember-central-navbar/helpers/pluralize-word", ["exports", "@clerky/ember-shared/helpers/pluralize-word"], function (_exports, _pluralizeWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pluralizeWord.default;
    }
  });
  Object.defineProperty(_exports, "pluralizeWord", {
    enumerable: true,
    get: function () {
      return _pluralizeWord.pluralizeWord;
    }
  });
});