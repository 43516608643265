define("ember-central-navbar/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PcBxgnNJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container body-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 global-error-page\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"OOPS! Something went wrong.\"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"illustration error-500-illustration\"],[12],[13],[2,\"\\n\\n      \"],[10,\"p\"],[14,0,\"lead\"],[12],[2,\"\\n        We're really sorry, but something went wrong.\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[2,\"\\n        We've been notified about this issue - we'll take a look and see what happened!\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"a\"],[14,0,\"btn btn-lg btn-primary-muted\"],[15,6,[31,[[30,[36,0],[\"/\"],null]]]],[12],[2,\"Back Home\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"central-url-for\"]}",
    "meta": {
      "moduleName": "ember-central-navbar/templates/error.hbs"
    }
  });

  _exports.default = _default;
});