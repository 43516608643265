define("ember-central-navbar/models/signature-holder-version", ["exports", "@clerky/ember-shared/models/signature-holder-version"], function (_exports, _signatureHolderVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _signatureHolderVersion.default;
    }
  });
});