define("ember-central-navbar/components/groups/membership-trigger-modal/template-family-selector", ["exports", "@clerky/ember-shared/components/groups/membership-trigger-modal/template-family-selector"], function (_exports, _templateFamilySelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _templateFamilySelector.default;
    }
  });
});