define("ember-central-navbar/components/document-set-template/clone-to-workspace-button", ["exports", "@clerky/ember-shared/components/document-set-template/clone-to-workspace-button"], function (_exports, _cloneToWorkspaceButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cloneToWorkspaceButton.default;
    }
  });
});