define("ember-central-navbar/mixins/model-type-extensions/uploaded-signed-copy", ["exports", "@clerky/ember-shared/mixins/model-type-extensions/uploaded-signed-copy"], function (_exports, _uploadedSignedCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uploadedSignedCopy.default;
    }
  });
});