define("ember-central-navbar/helpers/safe-html", ["exports", "@clerky/ember-shared/helpers/safe-html"], function (_exports, _safeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _safeHtml.default;
    }
  });
  Object.defineProperty(_exports, "safeHtml", {
    enumerable: true,
    get: function () {
      return _safeHtml.safeHtml;
    }
  });
});