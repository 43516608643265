define("ember-central-navbar/components/signature-block/signature-line", ["exports", "@clerky/ember-shared/components/signature-block/signature-line"], function (_exports, _signatureLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _signatureLine.default;
    }
  });
});