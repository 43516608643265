define("ember-central-navbar/helpers/format-usd", ["exports", "@clerky/ember-shared/helpers/format-usd"], function (_exports, _formatUsd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatUsd.default;
    }
  });
  Object.defineProperty(_exports, "formatUsd", {
    enumerable: true,
    get: function () {
      return _formatUsd.formatUsd;
    }
  });
});