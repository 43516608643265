define("ember-central-navbar/helpers/with-indefinite-article", ["exports", "@clerky/ember-shared/helpers/with-indefinite-article"], function (_exports, _withIndefiniteArticle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _withIndefiniteArticle.default;
    }
  });
  Object.defineProperty(_exports, "withIndefiniteArticle", {
    enumerable: true,
    get: function () {
      return _withIndefiniteArticle.withIndefiniteArticle;
    }
  });
});