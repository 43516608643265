define("ember-central-navbar/components/billing-setup-modal/company-lifetime-package-plan-summary", ["exports", "@clerky/ember-shared/components/billing-setup-modal/company-lifetime-package-plan-summary"], function (_exports, _companyLifetimePackagePlanSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _companyLifetimePackagePlanSummary.default;
    }
  });
});