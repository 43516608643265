define("ember-central-navbar/components/document-template-form-replacement/replication-template-item", ["exports", "@clerky/ember-shared/components/document-template-form-replacement/replication-template-item"], function (_exports, _replicationTemplateItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _replicationTemplateItem.default;
    }
  });
});