define("ember-central-navbar/models/mailing", ["exports", "@clerky/ember-shared/models/mailing"], function (_exports, _mailing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mailing.default;
    }
  });
});