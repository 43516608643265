define("ember-central-navbar/helpers/central-url-for", ["exports", "@clerky/ember-shared/helpers/central-url-for"], function (_exports, _centralUrlFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _centralUrlFor.default;
    }
  });
  Object.defineProperty(_exports, "centralUrlFor", {
    enumerable: true,
    get: function () {
      return _centralUrlFor.centralUrlFor;
    }
  });
});