define("ember-central-navbar/components/pill", ["exports", "@clerky/ember-shared/components/pill"], function (_exports, _pill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pill.default;
    }
  });
});