define("ember-central-navbar/app", ["exports", "ember-central-navbar/mixins/authentication", "ember-central-navbar/config/environment", "ember-load-initializers", "ember-resolver"], function (_exports, _authentication, _environment, _emberLoadInitializers, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  if (_environment.default.environment === 'test') {
    // Disable transitions for better visual diffs
    Ember.$(function () {
      Ember.$.support.transition = false;
      Ember.$.fx.off = true;
    });
  }

  Ember.Route.reopen(_authentication.default, {
    authenticationRequired: false
  });

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "rootElement", '#ember-central-navbar');
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});