define("ember-central-navbar/models/form-row", ["exports", "@clerky/ember-shared/models/form-row"], function (_exports, _formRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formRow.default;
    }
  });
});