define("ember-central-navbar/mixins/current-route-aware", ["exports", "@clerky/ember-shared/mixins/current-route-aware"], function (_exports, _currentRouteAware) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _currentRouteAware.default;
    }
  });
});