define("ember-central-navbar/helpers/inline-svg", ["exports", "ember-inline-svg/helpers/inline-svg", "ember-central-navbar/svgs"], function (_exports, _inlineSvg, _svgs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let helper;

  if (Ember.Helper && Ember.Helper.helper) {
    helper = Ember.Helper.helper(function ([path], options) {
      return (0, _inlineSvg.inlineSvg)(_svgs.default, path, options);
    });
  } else {
    helper = Ember.Handlebars.makeBoundHelper(function (path, options) {
      return (0, _inlineSvg.inlineSvg)(_svgs.default, path, options.hash || {});
    });
  }

  var _default = helper;
  _exports.default = _default;
});