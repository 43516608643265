define("ember-central-navbar/mixins/safe-runloop", ["exports", "@clerky/ember-shared/mixins/safe-runloop"], function (_exports, _safeRunloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _safeRunloop.default;
    }
  });
});