define("ember-central-navbar/models/legal-care-package-invitation-request", ["exports", "@clerky/ember-shared/models/legal-care-package-invitation-request"], function (_exports, _legalCarePackageInvitationRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _legalCarePackageInvitationRequest.default;
    }
  });
});