define("ember-central-navbar/models/form-part", ["exports", "@clerky/ember-shared/models/form-part"], function (_exports, _formPart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formPart.default;
    }
  });
});