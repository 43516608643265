define("ember-central-navbar/components/comment-list/document-version-copy-item", ["exports", "@clerky/ember-shared/components/comment-list/document-version-copy-item"], function (_exports, _documentVersionCopyItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentVersionCopyItem.default;
    }
  });
});