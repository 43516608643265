define("ember-central-navbar/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XILvzRUf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"isLoading\"],[true]]]]],\"hasEval\":false,\"upvars\":[\"main-navbar\"]}",
    "meta": {
      "moduleName": "ember-central-navbar/templates/application-loading.hbs"
    }
  });

  _exports.default = _default;
});