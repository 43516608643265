define("ember-central-navbar/modifiers/tether-to", ["exports", "@clerky/ember-shared/modifiers/tether-to"], function (_exports, _tetherTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tetherTo.default;
    }
  });
});