define("ember-central-navbar/components/billing-setup-modal/company-lifetime-package-plan-detail", ["exports", "@clerky/ember-shared/components/billing-setup-modal/company-lifetime-package-plan-detail"], function (_exports, _companyLifetimePackagePlanDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _companyLifetimePackagePlanDetail.default;
    }
  });
});