define("ember-central-navbar/components/document-set-template-price", ["exports", "@clerky/ember-shared/components/document-set-template-price"], function (_exports, _documentSetTemplatePrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentSetTemplatePrice.default;
    }
  });
});