define("ember-central-navbar/helpers/format-as-sentence", ["exports", "@clerky/ember-shared/helpers/format-as-sentence"], function (_exports, _formatAsSentence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatAsSentence.default;
    }
  });
  Object.defineProperty(_exports, "formatAsSentence", {
    enumerable: true,
    get: function () {
      return _formatAsSentence.formatAsSentence;
    }
  });
});