define("ember-central-navbar/initializers/custom-inflector-rules", ["exports", "@clerky/ember-shared/initializers/custom-inflector-rules"], function (_exports, _customInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _customInflectorRules.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _customInflectorRules.initialize;
    }
  });
});