define("ember-central-navbar/mixins/task-removal", ["exports", "@clerky/ember-shared/mixins/task-removal"], function (_exports, _taskRemoval) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _taskRemoval.default;
    }
  });
});