define("ember-central-navbar/models/field-template-form-token-relationship", ["exports", "@clerky/ember-shared/models/field-template-form-token-relationship"], function (_exports, _fieldTemplateFormTokenRelationship) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldTemplateFormTokenRelationship.default;
    }
  });
});