define("ember-central-navbar/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1zW90JWn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container body-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10 global-error-page\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"OOPS! Page Not Found!\"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"illustration error-illustration\"],[12],[13],[2,\"\\n\\n      \"],[10,\"p\"],[14,0,\"lead\"],[12],[2,\"\\n        Sorry, we were either unable to locate this page or unable to determine that you are authorized to view it.\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"a\"],[14,0,\"btn btn-lg btn-primary-muted\"],[15,6,[31,[[30,[36,0],[\"/\"],null]]]],[12],[2,\"Back to Dashboard\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"central-url-for\"]}",
    "meta": {
      "moduleName": "ember-central-navbar/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});