define("ember-central-navbar/macros/belongs-to-reference-is-loaded", ["exports", "@clerky/ember-shared/macros/belongs-to-reference-is-loaded"], function (_exports, _belongsToReferenceIsLoaded) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _belongsToReferenceIsLoaded.default;
    }
  });
});