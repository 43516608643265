define("ember-central-navbar/modifiers/acts-as-button", ["exports", "@clerky/ember-shared/modifiers/acts-as-button"], function (_exports, _actsAsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _actsAsButton.default;
    }
  });
});