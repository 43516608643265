define("ember-central-navbar/mixins/document-set-destruction", ["exports", "@clerky/ember-shared/mixins/model-type-extensions/document-set-destruction"], function (_exports, _documentSetDestruction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _documentSetDestruction.default;
    }
  });
});