define("ember-central-navbar/components/destroy-document-set-modal/refund-information", ["exports", "@clerky/ember-shared/components/destroy-document-set-modal/refund-information"], function (_exports, _refundInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _refundInformation.default;
    }
  });
});