define("ember-central-navbar/transforms/tracked-map", ["exports", "@clerky/ember-shared/transforms/tracked-map"], function (_exports, _trackedMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _trackedMap.default;
    }
  });
});