define("ember-central-navbar/components/field-errors", ["exports", "@clerky/ember-shared/components/field-errors"], function (_exports, _fieldErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldErrors.default;
    }
  });
});