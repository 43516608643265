define("ember-central-navbar/components/credit-card/remove-modal", ["exports", "@clerky/ember-shared/components/credit-card/remove-modal"], function (_exports, _removeModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _removeModal.default;
    }
  });
});