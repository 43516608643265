define("ember-central-navbar/components/signature-block/intermediate-signatory", ["exports", "@clerky/ember-shared/components/signature-block/intermediate-signatory"], function (_exports, _intermediateSignatory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _intermediateSignatory.default;
    }
  });
});