define("ember-central-navbar/models/filing-changeset", ["exports", "@clerky/ember-shared/models/filing-changeset"], function (_exports, _filingChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _filingChangeset.default;
    }
  });
});