define("ember-central-navbar/initializers/ember-data-model-extension", ["exports", "@clerky/ember-shared/initializers/ember-data-model-extension"], function (_exports, _emberDataModelExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emberDataModelExtension.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _emberDataModelExtension.initialize;
    }
  });
});