define("ember-central-navbar/components/document-template-form-replacement/field-group-template-container", ["exports", "@clerky/ember-shared/components/document-template-form-replacement/field-group-template-container"], function (_exports, _fieldGroupTemplateContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldGroupTemplateContainer.default;
    }
  });
});