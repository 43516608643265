define("ember-central-navbar/components/email-templates/other-references", ["exports", "@clerky/ember-shared/components/email-templates/other-references"], function (_exports, _otherReferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _otherReferences.default;
    }
  });
});