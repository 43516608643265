define("ember-central-navbar/helpers/breaklines", ["exports", "@clerky/ember-shared/helpers/breaklines"], function (_exports, _breaklines) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _breaklines.default;
    }
  });
  Object.defineProperty(_exports, "breaklines", {
    enumerable: true,
    get: function () {
      return _breaklines.breaklines;
    }
  });
});