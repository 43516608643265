define("ember-central-navbar/mixins/model-type-extensions/invoice-assignment-request-mailing", ["exports", "@clerky/ember-shared/mixins/model-type-extensions/invoice-assignment-request-mailing"], function (_exports, _invoiceAssignmentRequestMailing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _invoiceAssignmentRequestMailing.default;
    }
  });
});