define("ember-central-navbar/components/navigation-list/link", ["exports", "@clerky/ember-shared/components/navigation-list/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _link.default;
    }
  });
});