define("ember-central-navbar/models/email-request-mailing", ["exports", "@clerky/ember-shared/models/email-request-mailing"], function (_exports, _emailRequestMailing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _emailRequestMailing.default;
    }
  });
});